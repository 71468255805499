import apiClient from '@/services/api-client'

export async function fetchBalanceFilename($this, id) {
  function getQuery(id) {
    return `{
                balance(id: "` + id + `") {
                  filename                 
                }
              }`
  }
  return apiClient
    .post('/graphql', { query: getQuery(id) })
    .then(response => {
      $this.filename = response.data.data.balance.filename
    })
    .catch(err => console.error(err))
}

export async function fetchBalanceTypes($this, id) {
  function getQuery(id) {
    return `{
                balance(id: "` + id + `") {
                  types                 
                }
              }`
  }
  return apiClient
    .post('/graphql', { query: getQuery(id) })
    .then(response => {
      $this.types = response.data.data.balance.types
    })
    .catch(err => console.error(err))
}

export async function fetchBalanceDataByType($this, id, type) {
  function getQuery(id, type) {
    return `{
                balance(id: "` + id + `") {
                  data {
                    ` + type + `
                  }
                }
              }`
  }
  return apiClient
    .post('/graphql', { query: getQuery(id, type) })
    .then((response) => {
      const source = response.data.data.balance.data
      $this.$data.data = JSON.parse(source[type])
    })
    .catch(err => console.error(err))
}

export async function fetchBalanceQualityByType($this, id, type, canNotify) {
  function notify(type, oldRate, newRate) {
    const description = type.charAt(0).toUpperCase() + type.slice(1) + ' section matches ' + newRate + '% quality rate.'
    if (newRate >= oldRate) {
      $this.$notification.info({
        message: 'Good work. Improvements happen',
        description: description,
      })
    }
    if (newRate === 100) {
      $this.$notification.success({
        message: 'Top quality score reached',
        description: description,
      })
    } else if (newRate >= 95) {
      $this.$notification.warn({
        message: 'Quality is above 95%. Continue good work',
        description: description,
      })
    } else {
      $this.$notification.error({
        message: 'Quality is below 95%',
        description: description,
      })
    }
  }
  function getQuery(id, type) {
    return `{
              balanceQuality(balanceId: "` + id + '", key: "' + type + `") {
                rate
                badKeys
              }
            }`
  }
  return apiClient
    .post('/graphql', { query: getQuery(id, type) })
    .then((response) => {
      const quality = response.data.data.balanceQuality
      if (canNotify) {
        notify(type, $this.rate, quality.rate)
      }
      $this.badKeys = quality.badKeys
      $this.rate = quality.rate
    })
    .catch(err => console.error(err))
}

export async function updateBalanceDataByType($this, id, type, section) {
  return apiClient
    .post('/api/balance/data/' + id + '/' + type + '/' + section, $this.$data.data[section])
    .then((response) => {
      fetchBalanceQualityByType($this, id, type, true)
    })
    .catch(err => console.error(err))
}

export async function fetchBalanceAdmin($this, id) {
  function getQuery(id) {
    return `{
                balance(id: "` + id + `") {
                  id
                  reference
                  currency
                  startDateAsString
                  depositDateAsString
                  endDateAsString
                  company {
                    name
                    registrationNumber
                    rcslNumber
                  }
                }
              }`
  }
  return apiClient
    .post('/graphql', { query: getQuery(id) })
    .then((response) => {
      var result = response.data.data.balance
      $this.reference = result.reference
      $this.company = result.company
      $this.currency = result.currency
      $this.year = result.year
      $this.depositDate = result.depositDateAsString
      $this.startDate = result.startDateAsString
      $this.endDate = result.endDateAsString
    })
    .catch(err => console.error(err))
}

export async function updateBalanceAdmin($this, id) {
  return apiClient
    .post('/api/balance/admin/' + id, {
      company: $this.company,
      reference: $this.reference,
      currency: $this.currency,
      depositDate: $this.depositDate,
      startDate: $this.startDate,
      endDate: $this.endDate,
    })
    .catch(err => console.error(err))
}

export async function exportBalance(mimeType, id, name) {
  return apiClient
    .get('/api/balance/download/' + mimeType + '/' + id, { responseType: 'blob' })
    .then(response => {
      const blob = new Blob([response.data], { type: 'application/' + mimeType })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = name
      link.click()
      URL.revokeObjectURL(link.href)
    })
    .catch(err => console.error(err))
}

export async function exportBalances(ids) {
  return apiClient
    .get('/api/balance/export', { params: { ids: ids.length > 0 ? encodeURI(ids) : null }, responseType: 'blob' })
    .then(response => {
      const blob = new Blob([response.data], { type: 'application/zip' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = 'export.zip'
      link.click()
      URL.revokeObjectURL(link.href)
    })
    .catch(console.error)
}

export async function retry(ids, callback) {
  return apiClient
    .post('/api/event/retry', { ids: ids })
    .then(response => {
      callback()
    })
    .catch(console.error)
}

export async function deleteBalanceEvents(ids, callback) {
  return apiClient
    .post('/api/event/delete', { ids: ids })
    .then(response => {
      callback()
    })
    .catch(console.error)
}

export async function deleteBalance(id, callback) {
  return apiClient
    .get('/api/balance/delete/' + id)
    .then(response => {
      callback()
    })
    .catch(console.error)
}

export async function fetchRunningBalanceEventCount($this) {
  return apiClient
    .post('/graphql', { query: '{ runningBalanceEventCount }' })
    .then((response) => {
      $this.runningBalanceEventCount = response.data.data.runningBalanceEventCount
    })
    .catch(err => console.log(err))
}

export async function fetchBalanceEventList($this, page, size, status) {
  function getQuery(page, size, status) {
    return `{
           balanceEventList(page: ` + page + ', size: ' + size + ', status: "' + status + `") {
              totalPages
              totalElements
              length
              last
              content {
                id
                filename
                createdDateTimestamp
                duration
                status
                message
                balance {
                  id
                  reference
                  year
                  abridged
                  types
                  qualityList {
                    type
                    rate
                  }
                  company {
                    name
                    rcslNumber
                  }
                }
            }
          }
        }`
  }
  return apiClient
    .post('/graphql', { query: getQuery(page, size, status) })
    .then((response) => {
      const pagination = { ...$this.pagination }
      pagination.total = response.data.data.balanceEventList.totalElements
      pagination.pageSize = size
      pagination.position = 'both'
      $this.pagination = pagination
      $this.totalElements = response.data.data.balanceEventList.totalElements
      $this.length = response.data.data.balanceEventList.length
      $this.balanceEventList = response.data.data.balanceEventList.content
    })
    .catch(err => console.log(err))
}
